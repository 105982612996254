<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span  >接口配置选择</span>
            </div>
        </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
        <a-form-model-item label="接口配置" prop="name">
            <a-select :showSearch="true" placeholder="请选择接口配置" :fieldMap="fieldMap" v-model="formModel.interfaceName" :options="interfaceOptions">
            </a-select>
        </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
        <a-button type="danger" @click="cancel">取消</a-button>
        <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
   import sysInterfaceApi  from "../../../../shared/service-proxies/sysInterface/services/sys-interface-proxies";
    export default {
        name: "sys-interface-select-from",
        mixins: [AppComponentBase, ModalComponentBase],
        data(){
             return {
                 spinning:false,
                 fieldMap:{
                     key:'name',
                     label:'name',
                     value:'name'
                 },
                 interfaces:[],
                 formModel:{
                      interfaceName:""
                 },
                 validModel:{},
                 formItemLayout:"",

             }
        }
        ,created() {
            this.loadData()
        },
         computed:{
             interfaceOptions(){
                  return  this.interfaces.map((item)=>{
                      return {
                          label:item.name,
                          value:item.name,
                          title:item.name
                      }
                  })
             }
         },
         methods:{
             confirmEvent(){
                 console.log('confirmEvent！！')

                 let interfaceInfo =  this.interfaces.find((item)=>{
                      return  item.name   ==  this.formModel.interfaceName
                 })

                 console.log(this.tables)
                 interfaceInfo.params = JSON.parse(interfaceInfo.params)
                 interfaceInfo.headers = JSON.parse(interfaceInfo.headers)
                 interfaceInfo.fields = JSON.parse(interfaceInfo.fields)
                 this.confirm(interfaceInfo)
                 this.close()
             },
             cancel(){
                 this.close()
             },
             loadData(){
                 sysInterfaceApi.getPagedList({
                     filterText: '',
                     sorting: '',
                     maxResultCount:1000,
                     skipCount:0
                 }).then(
                     (data)=>{
                         console.log('databaseApi.getTables')
                         console.log(data)
                         this.interfaces = data.items
                     }
                 ).catch((error)=>{console.log(error)})
             }
         }
    }
</script>

<style scoped>

</style>