<template>
    <a-spin :spinning="spinning">
        <!-- <page-header title="业务表单管理"></page-header> -->
            <!-- <react-table
                :actions-type="actionsType"
                :showTotal="showTotalFun"
                :table-data="tableData"
                :tableKey="tableKey"
                :total-items="totalItems"
                :hide-row-selection="true"
                :is-full="true"
                :current-page="pageNumber"
                @emitDeleteItem="deleteItem"
                @emitEdit="openForm"
                @emitOnPageChange="updatePageChange"
                @emitRefreshData="getData"
                @emitSelectChange="updateSelectChange"
                @emitShowSizeChange="updateShowSizeChange"
                @emitSortData="updateSortData"
            > -->
            <ele-table
                :table-data="tableData"
                :total-items="totalItems"
                :tableKey="tableKey"
                :is-full="true"
                :actions-type="actionsType"
                :current-page="pageNumber"
                :hide-row-selection="true"
                @emitSelectChange="updateSelectChange"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            >
                <a-row :gutter="8">
                    
                    <a-col :span="6" class="gutter-row">
                        <a-input
                                :placeholder="l('SearchWithThreeDot')"
                                v-model.trim="filterText"
                        />
                    </a-col>
                    <a-col :span="4" class="gutter-row">
                        <a-button @click="search" type="primary" v-if="isGranted('Pages.FormSchemeInfo.Query')">
                            {{ l("Search") }}
                        </a-button>
                        <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                    </a-col>

                    <a-col :span="14" style="text-align: right;">
                        <a-button
                            @click="openForm('')"
                            type="primary"
                            v-if="isGranted('Pages.FormSchemeInfo.Create')"
                        >
                            <a-icon type="plus"/>
                            {{ l("Create") }}
                        </a-button>

                    </a-col>
                </a-row>
            </ele-table>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalHelper} from "@/shared/helpers";
    import formSchemeApi from "@/shared/service-proxies/formScheme/services/form-scheme-proxies";
    import form from "./form.vue";
    import objectHelper from "../../../shared/helpers/objectHelper";
    import moment from "moment";
    import EleTable from "@/components/ele-table";
    import FormBuilder from "./builder/builder.vue";
    import container from "../../../shared/common/container/container";

    export default {
        mixins: [AppComponentBase],
        name: "customize-form-index",
        components: {EleTable},
        data() {
            return {
                queryModel: {
                    parentId: '',
                    name: ''
                },
                spinning: false,
                // 搜索
                filterText: "",
                // 选择多少项
                selectedRowKeys: [],
                tableData: [],
                tableKey: "FormSchemeInfoPagedDto",
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {
                    sorting: "",
                    maxResultCount: 50,
                    skipCount: 0,
                },
                _serviceProxy: "",
                commonService: null,
                actionsType: {},
            };
        },
        created() {
            this.initActionsType();
            this.getData();
        },
        methods: {
            search() {
                this.pageNumber = 1
                this.request.skipCount = 0
                this.getData()
            },
            /**
             * 获取数据
             */
            getData() {
                this.spinning = true;
                formSchemeApi.getPagedList({
                    filterText: this.filterText,
                    sorting: this.request.sorting,
                    maxResultCount: this.request.maxResultCount,
                    skipCount: this.request.skipCount
                }).then((res) => {
                    this.spinning = false;
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    console.log(res)
                    console.log(this.request.maxResultCount)
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    console.log(this.totalPages)
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                    console.log(this.pagerange)
                }).catch((data, error) => {
                    this.spinning = false;
                })
            },
            /**
             * table选择事件
             */
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * table选择事件
             */

            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 重置
             */
            refreshGoFirstPage() {
                this.filterText = "";
                this.request.skipCount = 0;
                this.getData();
            },
            /**
             * 新建修改
             */
            async openForm(param) {
                console.log('openForm')
                console.log(param!='')
                let mode = 'create'
                let activeTableName = ''
                let activeInterfaceName =''
                this.spinning = true
                if (param != '') {
                    mode = 'edit'
                    //获取当前表单详细配置

                    console.log('edit!!!!!!')
                    console.log(param)
                    let res = await formSchemeApi.getScheme(param.schemeId)
                    console.log(res)
                    let schemeJson = JSON.parse(res.scheme)
                    this.info(schemeJson)
                     this.info(!!schemeJson.interfaceConfig)
                     this.info(typeof (schemeJson.interfaceConfig) == "undefined")
                    let formDefined = schemeJson.formDefined
                    // formDefined: formDefined,
                    //     //todo  自定义参数
                    //     // paramsConfig:paramsConfig,
                    //     //todo 接口配置
                    //     //interfaceConfig:interfaceConfig,
                    //     dataStoreConfig: dataStoreConfig,
                    //属性补完
                    if (!(!!schemeJson.interfaceConfig)) {
                        schemeJson.interfaceConfig = {
                            interfaces: []
                        }

                    }
                    if (!!schemeJson.dataStoreConfig) {
                        schemeJson.dataStoreConfig = {
                            tables: []
                        }
                    }
                    if (!!schemeJson.dataStoreConfig.tables) {
                        schemeJson.dataStoreConfig = {
                            tables: []
                        }
                    }

                    if (typeof (schemeJson.interfaceConfig) == "undefined") {
                        schemeJson.interfaceConfig = {
                            interfaces: []
                        }
                    }
                    param.interfaceConfig = schemeJson.interfaceConfig
                    param.formDefined = formDefined
                    param.dataStoreConfig = schemeJson.dataStoreConfig
                    param.dataStorageArea = res.dataStorageArea
                    if (param.dataStoreConfig.tables.length > 0) {
                        activeTableName = param.dataStoreConfig.tables[0].name
                    }
                    if (param.interfaceConfig.interfaces.length > 0) {
                        activeInterfaceName = param.interfaceConfig.interfaces[0].name
                    }
                } else {
                    param = {
                        dataStoreConfig: {
                            tables: []
                        },
                        interfaceConfig: {
                            interfaces: []
                        },
                        params: []
                    }
                }
                ModalHelper.create(
                    form,
                    {
                        mode: mode,
                        activeTableName:activeTableName  ,
                        activeInterfaceName:activeInterfaceName,
                        formModel: {
                            ...param,

                        },
                    },
                    {
                        width: "1200px",
                        isChange: true
                    }
                ).subscribe((res) => {
                    console.log('messageRevecived')
                    console.log(res)
                    this.refreshGoFirstPage();
                });

                this.spinning = false
            },

            /**
             * 清空
             */
            restCheckStatus() {
                this.selectedRowKeys = [];
            },
            /**
             * 单个删除
             */
            deleteItem(item) {
                console.log(item);
                console.log(item.item.id);
                let obj = {};
                obj.id = item.item.id;
                const body = JSON.stringify(obj);
                this.spinning = true;
                this._serviceProxy
                    .changeStatus(body)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(() => {
                        this.refreshGoFirstPage();
                        this.$notification["success"]({
                            message: this.l("SuccessfullyDeleted"),
                        });
                    });
            },
            /**
             * 导出为excel
             */
            exportToExcel() {
                this.spinning = true;
                this._serviceProxy.getToExcelFile().then((result) => {
                    this.spinning = false;
                    fileDownloadService.downloadTempFile(result);
                });
            },
            /**
             * 排序更新data
             */
            updateSortData(newV) {
                let {columnKey, order} = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            /**
             * 分页
             */
            updatePageChange(newV) {
                let {page, pageSize} = newV;
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let {current, size} = newV;
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * table选择事件
             */
            updateSelectChange(newV) {
                console.log(newV);
                let {selectedRowKeys, selectedRows} = newV;
                this.selectedRowKeys = selectedRowKeys;
            },
            createInstance(row) {
                let mode = 'create'
                ModalHelper.create(
                    FormBuilder,
                    {
                        mode: mode,
                        instanceId: '',
                        formSchemeInfo: {
                            ...row
                        }
                    },
                    {
                        width: "1200px",
                        isChange: true
                    }
                ).subscribe((res) => {
                    console.log('messageRevecived')
                    console.log(res)
                    this.refreshGoFirstPage();
                });
            },
            /**
             * 初始化需要显示的func btn
             */
            initActionsType() {
                console.log('initActionsType')
                let _that = this
                let obj = {
                    type: "sys-interface-action",
                    isShow: true,
                    fns: {
                        dropList: [{
                            granted: this.isGranted('Pages.FormSchemeInfo.Edit'), name: this.l("修改"), icon: "profile", fn: (data) => {
                                _that.openForm(data)
                            },
                        }, {
                            granted: this.isGranted('Pages.FormSchemeInfo.CreateIns'), name: '创建实例', icon: "profile",
                            fn: (data) => {
                                _that.createInstance(data)
                            }
                        },]
                    },
                };
                this.actionsType = obj;
            },
        },
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .table-plus,
    .table-delete {
        margin-left: 10px;
    }
</style>
