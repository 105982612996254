<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span span v-if="formModel.id"
                >{{ mode === "edit" ? "修改" : "" }}{{ formModel.name }} </span
                >
                <span span v-if="!formModel.id">创建业务表单</span>

            </div>
        </div>

        <a-form-model :layout="form.layout" :model="formModel" :rules="validModel" ref="ruleForm"
                      v-bind="formItemLayout">
            <a-tabs v-model="curTab">
                <a-tab-pane key="baseInfo">
                <span slot="tab">
                    基本信息
                </span>
                    <a-form-model-item label="表单名称" prop="name">
                        <a-input placeholder="请输入表单名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="表单分类" prop="category">
                        <a-select :options="options('form.type')" v-model="formModel.category"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="是否启用" prop="enableMark">
                        <a-select :options="options('Int.YN')" v-model="formModel.enableMark"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="存储区域" prop="dataStorageArea">
                        <a-select :options="options('Enum.FormDataStorageArea')"
                                  :defaultValue="formModel.dataStorageArea"
                                  v-model="formModel.dataStorageArea"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="表单描述" prop="description">
                        <a-textarea placeholder="请填写表单描述" style="height: 200px"
                                    v-model="formModel.description">
                        </a-textarea>
                    </a-form-model-item>
                </a-tab-pane>

                <!-- 专用域的时候显示-->
                <a-tab-pane key="dataStore" v-if="formModel.dataStorageArea == 'Speciated'">
                  <span slot="tab">
                      数据存储
                  </span>

                    <a-alert close-text="好的" message="//todo主从表实现" style="margin-bottom:10px"
                             type="info"/>
                    <a-tabs v-model="activeTableName"        tab-position="left"   @edit="editTable" data-target="tables" type="editable-card" style="min-height: 50px;margin-top:10px" >
                        <a-tab-pane   v-if="formModel.dataStoreConfig.tables.length<1" :closable="false">
                         <span slot="tab">
                              请添加表空间
                         </span>
                        </a-tab-pane>



                        <a-tab-pane :key="item.name" @close="removeTable" :closable="true"
                                    v-for=" (item,i) in formModel.dataStoreConfig.tables">
                             <span slot="tab">
                                 {{item.name}}
                             </span>
                            <a-descriptions title="表空间定义">
                                <a-descriptions-item v-if="key!='fields'" v-for="(value,key,index) in item"
                                                     :label="key">
                                    {{value}}
                                </a-descriptions-item>
                            </a-descriptions>
                            <a-descriptions title="表空间字段">
                            </a-descriptions>
                            <a-table rowKey="number" :row-selection="rowSelection" :columns="fieldTableColumns"
                                     :data-table="item.name"
                                     :data-source="item.fields">
                            </a-table>
                        </a-tab-pane>
                    </a-tabs>
                </a-tab-pane>

                <a-tab-pane key="interfaceDependency">
                  <span slot="tab">
                   接口依赖
                  </span>

                    <!--                    //todo todo调用接口与绑定到接口实现-->
                    <a-alert close-text="好的" message="//todo调用接口与绑定到接口实现" style="margin-bottom:10px"
                             type="info"/>


                    <a-tabs      tab-position="left"   style="min-width:100px;:margin-top:10px;min-height: 50px" type="editable-card" v-model="activeInterfaceName"
                            @edit="editInterface" data-target="interfaces">

                        <a-tab-pane   v-if="formModel.interfaceConfig.interfaces.length<1" :closable="false">
                                                     <span slot="tab">
                                                          请添加接口镜像
                                                     </span>
                        </a-tab-pane>


                        <a-tab-pane :key="item.name" @close="removeInterface" :closable="true" :data-index="i"
                                    v-for=" (item,i) in formModel.interfaceConfig.interfaces">
                                                     <span slot="tab"  style="text-align: center">
                                                         {{item.name}}
                                                     </span>
                            <a-descriptions title="接口基本信息">
                                <a-descriptions-item v-if="(key!='fields'&&key!='headers'&&key!='params')"
                                                     v-for="(value,key,index) in item"
                                                     :label="key">
                                    {{value}}
                                </a-descriptions-item>
                            </a-descriptions>
                            <a-descriptions title="接口头部参数">
                            </a-descriptions>
                            <a-table rowKey="number" :columns="interfaceHeadersColumns"
                                     :data-source="item.headers">
                            </a-table>
                            <a-descriptions title="接口参数">
                            </a-descriptions>
                            <a-table rowKey="number" :columns="interfaceParamsColumns"
                                     :data-source="item.params">
                            </a-table>
                            <a-descriptions title="接口响应字段">
                            </a-descriptions>
                            <a-table rowKey="number" :columns="interfaceFieldsColumns"
                                     :data-source="item.fields">
                            </a-table>
                        </a-tab-pane>
                    </a-tabs>
                </a-tab-pane>

                <a-tab-pane key="customParams">
                  <span slot="tab">
                      自定义参数
                </span>
                    <!--  //todo 未实现-->
                    <a-row>
                        <a-col :span="24">
                            <a-button @click="addParam" data-target="params" type="primary"> +</a-button>
                        </a-col>
                    </a-row>
                    <a-row v-for="(item,i) in formModel.params">

                        <a-col :span="7">
                            <a-form-model-item :label="'参数名称'" :labelCol="labelCol">
                                <a-input placeholder="参数名称" v-model="item.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="7">
                            <a-form-model-item :label="'表达式'" :labelCol="labelCol">
                                <a-input placeholder="值或表达式$name" v-model="item.value"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="8">
                            <a-form-model-item :label="'参数类型'" :labelCol="labelCol">
                                <a-input placeholder="参数类型(不写默认为string)" v-model="item.type"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="2">
                            <a-button :data-index="i" :data-item="item" @click="removeItem" data-target="params"
                                      style="margin-top: 4px" type="danger">-
                            </a-button>
                        </a-col>
                    </a-row>
                </a-tab-pane>


                <a-tab-pane key="formDesign" forceRender>
                  <span slot="tab">
                   表单设计
                  </span>
                    <zr-form-designer :interfaceConfig="formModel.interfaceConfig"
                                      :tablesConfig="formModel.dataStoreConfig" ref="designer"
                                      @handleSave="getFormDesignerJson" :drawerShowController="drawerShowController"/>
                </a-tab-pane>

                <a-tab-pane key="paramsBinding">
                  <span slot="tab">
                    参数绑定
                </span>
                    <a-alert close-text="我知道了" message="目标接口目前仅支持内部接口//todo 外部接口模拟" style="margin-bottom:10px"
                             type="info"/>

                    <a-row style="margin-top: 10px">
                        <a-button @click="simulateRequest" type="primary">模拟请求</a-button>
                    </a-row>

                    <a-row style="margin-top: 10px">
                        <a-col :span="24">
                            <a-button @click="addItem" data-target="fields" type="primary">+</a-button>
                        </a-col>
                    </a-row>
                    <a-row v-for="(item,i) in formModel.fields" :key="item.path">
                        <a-col :span="7">
                            <a-form-model-item :label="'参数名称'" :labelCol="labelCol">
                                <a-input placeholder="参数名称" v-model="item.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="7">
                            <a-form-model-item :label="'表达式'" :labelCol="labelCol">
                                <a-input placeholder="表达式$.name" v-model="item.path"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="8">
                            <a-form-model-item :label="'参数类型'" :labelCol="labelCol">
                                <a-input placeholder="参数类型(不写默认为string)" v-model="item.type"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="2">
                            <a-button :data-index="i" :data-item="item" @click="removeItem" data-target="fields"
                                      style="margin-top: 4px" type="danger">
                            </a-button>
                        </a-col>
                    </a-row>
                </a-tab-pane>
                <a-tab-pane key="formTesting">
                  <span slot="tab">
                    表单测试
                  </span>
                    <a-button @click="loadForm"> 加载表单</a-button>
                    <a-button @click="viewFormDefined"> 查看表单定义Json</a-button>
                    <JsonModal ref="jsonModal"></JsonModal>
                    <previewModal ref="previewModal"/>
                </a-tab-pane>
            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button @click="cancle" type="danger">取消</a-button>
            <a-button @click="onSubmit" type="primary">保存</a-button>

        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "../../../shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import tableSelectFrom from "../../database/tableSelectForm/index.vue"
    import sysInterfaceSelectForm from "../sysInterface/selectForm/index.vue"
    import {ModalHelper} from "@/shared/helpers";
    import {ZrFormDesigner} from "../../../components/ZrCustomizeForm";
    import databaseApi from "../../../shared/service-proxies/dataBase/services/database-proxies";
    import previewModal from "@/components/ZrCustomizeForm/ZRFormPreview/index.vue";
    import formSchemeApi from "../../../shared/service-proxies/formScheme/services/form-scheme-proxies";

    import Bus from "@/shared/bus/bus";
    import JsonModal from "../../../components/ZrCustomizeForm/ZRFormDesign/module/jsonModal";
    import category from "../../agreement/category/category";

    export default {
        name: "customize-form-form",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                formDesigner: {
                    jsonData: "",
                    previewWidth: 1000,
                    visible: true
                },
                curTab: 'baseInfo',
                //dataItemApi: dataItemApi,
                activeTableName: "",
                activeInterfaceName: "",
                interfaceHeadersColumns: [
                    {
                        title: '参数名',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '类型',
                        dataIndex: 'type',
                        key: 'type',
                    }, {
                        title: '表达式/常量',
                        dataIndex: 'value',
                        key: 'value',
                    },
                ],
                interfaceParamsColumns: [
                    {
                        title: '参数名',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '类型',
                        dataIndex: 'type',
                        key: 'type',
                    }, {
                        title: '常量',
                        dataIndex: 'value',
                        key: 'value',
                    },
                    {
                        title: '变量表达式',
                        dataIndex: 'path',
                        key: 'path',
                    }
                ],
                interfaceFieldsColumns: [
                    {
                        title: '作用域',
                        dataIndex: 'activedScope',
                        key: 'activedScope',
                    },
                    {
                        title: '参数名',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '提取表达式',
                        dataIndex: 'path',
                        key: 'path',
                    }, {
                        title: '数据类型',
                        dataIndex: 'type',
                        key: 'type',
                    },
                ],
                fieldTableColumns: [
                    {
                        title: '字段名',
                        dataIndex: 'columnName',
                        key: 'columnName',
                    },
                    {
                        title: '数据类型',
                        dataIndex: 'dataType',
                        key: 'dataType',
                    },
                    {
                        title: '长度',
                        dataIndex: 'length',
                        key: 'length',
                    },
                    {
                        title: '是否可以为空',
                        dataIndex: 'nullable',
                        key: 'nullable',
                    },
                    {
                        title: '默认值',
                        dataIndex: 'defaults',
                        key: 'defaults',
                    },
                    {
                        title: '附注',
                        dataIndex: 'remarks',
                        key: 'remarks',
                    },

                ],
                labelCol: {
                    span: 6, offset: 0
                },
                formTypes: [],
                YNOptions: [],
                fieldMaps: {
                    requestMethods: {
                        value: 'id',
                        label: 'itemName',
                    }
                },
                form: {
                    layout: 'horizontal',
                },
                mode: 'create',
                curDataItemTree: [],
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    dataStoreConfig: {
                        tables: []
                    },
                    interfaceConfig: {
                        interfaces: []
                    },
                    enableMark: 1,
                    dataStorageArea: 'General',
                    id: '',
                    name: '',
                    type: '',
                    path: '',
                    params: [{name: '', value: '', type: ''}],
                },
                validModel: {
                    name: [
                        {required: true, message: '请输入!'}
                    ],
                    category: [
                        {required: true, message: '请输入!'}
                    ],
                    enableMark: [
                        {required: true, message: '请输入!'}
                    ],
                    dataStorageArea: [
                        {required: true, message: '请输入!'}
                    ],
                }
                ,
            }
        },

        components: {
            JsonModal,
            ZrFormDesigner,
            previewModal,
        },
        removeTable() {
            console.log('removeTable')
        },
        created() {
            this.fullData();
        },
        mounted() {
            if (this.mode == 'edit') {
                this.$refs.designer.data = this.formModel.formDefined
            }

        },
        computed: {
            drawerShowController() {
                return this.curTab == 'formDesign'
            },
            rowSelection() {
                let that = this
                return {
                    type: "radio",
                    columnTitle: '关联字段',
                    columnWidth: '150px',
                    getCheckboxProps(record) {
                        let tableIndex = that.formModel.dataStoreConfig.tables.findIndex((table) => {
                            return table.name == that.activeTableName
                        })
                        console.log(record.columnName)
                        return {
                            props: {
                                defaultChecked: that.formModel.dataStoreConfig.tables[tableIndex].relatedField == record.columnName,
                                value: record.columnName,
                            }
                            //,defaultChecked:true
                        }
                    },
                    onChange(index, item) {
                        let tableIndex = that.formModel.dataStoreConfig.tables.findIndex((table) => {
                            return table.name == that.activeTableName
                        })

                        that.formModel.dataStoreConfig.tables[tableIndex].relatedField = item[0].columnName
                    }
                }
            },
            fieldsJsonForPerView() {
                try {
                    return JSON.parse(this.fieldsJsonStr)
                } catch (ex) {
                }
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            viewFormDefined() {
                console.log('loadForm')
                let data = this.$refs.designer.data
                console.log(data)
                this.$refs.jsonModal.jsonData = data
                this.$refs.jsonModal.previewWidth = 800
                this.$refs.jsonModal.visible = true
            },
            loadForm() {
                console.log('loadForm')
                let data = this.$refs.designer.data
                console.log(data)
                console.log(this.$refs.previewModal.jsonData)
                this.$refs.previewModal.jsonData = data
                console.log(this.$refs.previewModal.visible)
                this.$refs.previewModal.previewWidth = 800
                this.$refs.previewModal.visible = true
                console.log(data)
            },
            getFormDesignerJson(json) {
                console.log(json)
            },
            editInterface(i, e) {
                console.log(e)
                if (e == 'add') {

                    ModalHelper.create(
                        sysInterfaceSelectForm,
                        {
                            formModel: {
                                interfaceName: ''
                            },
                        },
                        {
                            confirm: (data) => {
                                console.log(data)
                                this.formModel.interfaceConfig.interfaces.push(data)
                                this.activeInterfaceName = data.name
                            },
                            width: "400px",
                            isChange: true
                        }
                    ).subscribe((res, message) => {
                        console.log('messageRevecived')
                        console.log(res)
                        console.log(message)
                    });
                } else if (e == 'remove') {
                    this.removeInterface(i, e)
                }
            },
            jsonFieldsStrChanged() {
                console.log('jsonFieldsStrChanged')
                console.log(this.fieldsJsonStr)
                let temp = {
                    name: '',
                    path: '',
                    value: '',
                    type: ''
                }
                let fieldJsonList = []
                try {
                    let fieldJson = JSON.parse(this.fieldsJsonStr)
                    for (let item in fieldJson) {
                        let fieldPath = '$.' + item
                        fieldJsonList.push({
                            name: item,
                            path: fieldPath,
                            value: "",
                            type: typeof fieldJson[item]
                        })
                        if (typeof fieldJson[item] == "object") {
                            let getFieldList = function (path, object, list) {
                                for (let i in object) {
                                    let curFieldPath = path + '.' + i
                                    list.push({
                                        name: i,
                                        path: curFieldPath,
                                        value: "",
                                        type: typeof object[i]
                                    })

                                    if (typeof typeof object[i] == "object") {
                                        return getFieldList(curFieldPath, object[i], list)
                                    }
                                }
                            }
                            getFieldList(fieldPath, fieldJson[item], fieldJsonList)
                        }
                    }
                    console.log(fieldJsonList)
                    this.formModel.fields = fieldJsonList
                } catch (ex) {
                    console.log(ex)
                    message.error('解析响应报文失败！')
                }
            },

            //模拟请求
            simulateRequest(e) {
                console.log(this.formModel.path)
                sysInterfaceApi.simulateRequest(
                    this.formModel.path, this.formModel.type, this.formModel.method, this.formModel.headers, this.formModel.params
                ).then((resp) => {
                    this.fieldsJsonStr = JSON.stringify(resp)
                    this.jsonFieldsStrChanged()
                    //console.log(resp)
                })
            },
            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            editTable(i,e) {
                 if (e == 'add'){
                ModalHelper.create(
                    tableSelectFrom,
                    {
                        formModel: {
                            tableName: ''
                        },
                    },
                    {
                        confirm: (data) => {
                            console.log(data)
                            databaseApi.getTableDefined(data.name).then((res) => {
                                data.fields = res.items
                                data.relatedField = data.pk
                                this.formModel.dataStoreConfig.tables.push(data)
                                this.activeTableName = data.name
                            })

                        },
                        width: "400px",
                        isChange: true
                    }
                ).subscribe((res, message) => {
                    console.log(res)
                    console.log(message)
                });
                 }  else if (e =='remove'){
                      this.removeTable(i,e)
                 }
            },
            addParam() {
                console.log(this.formModel['params'])
                this.formModel['params'].push({
                    name: '', value: '', type: ''
                })
            },
            addItem(e) {
                console.log(e)

            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    if (!valid) {
                        message.error('表单验证失败，请检查后重新提交！')
                        return false;
                    }

                    //参数配置
                    let paramsConfig = this.formModel.params

                    //接口配置
                    let interfaceConfig = this.formModel.interfaceConfig

                    //数据存储配置
                    let dataStoreConfig = this.formModel.dataStoreConfig

                    //formDefined 表单配置
                    let formDefined = this.$refs.designer.data
                    console.log(this.$refs.designer)
                    console.log(formDefined)

                    let formInfo = {
                        id: this.formModel.id,
                        dataStorageArea: this.formModel.dataStorageArea,
                        category: this.formModel.category,
                        enableMark: this.formModel.enableMark,
                        name: this.formModel.name,
                        type: this.formModel.type,
                        description: this.formModel.description,
                        formScheme: JSON.stringify({
                            formDefined: formDefined,
                            //todo 自定义参数
                            // paramsConfig:paramsConfig,
                            //todo 接口配置
                            interfaceConfig: interfaceConfig,
                            dataStoreConfig: dataStoreConfig,
                        })
                    }

                    console.log(formInfo)
                    formSchemeApi.save(formInfo).then((data) => {
                        this.$notification["success"]({
                            message: "保存成功",
                        });
                        this.close()
                    }).catch((error) => {
                        console.log('-----oh 我的玉帝 报错了---------')
                        console.log(error)
                    })
                });

            },
            removeInterface(i, e) {
                console.log(i, e)
                this.formModel.interfaceConfig.interfaces = this.formModel.interfaceConfig.interfaces.filter((item) => {
                    return item.name != i
                })

            },
            removeTable(i, e) {
                console.log(i, e)
                this.formModel.dataStoreConfig.tables = this.formModel.dataStoreConfig.tables.filter((item) => {
                    return item.name != i
                });
            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
