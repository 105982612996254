/*
 * @Description: 增加antd ui 组件
 * @Author: zr
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: zr
 * @LastEditTime: 2020-07-07 23:12:33
 */

// 导入ant组件
import './core/components_use';
import * as all from './mini';

export default all.default;
export let setFormDesignConfig = all.setFormDesignConfig;
export let ZrFormDesigner = all.ZrFormDesign;
export let ZrFormPreview = all.ZrFormPreview;
export let ZrFormBuild = all.ZrFormBuild;
export let ZrFormItem = all.ZrFormItem;
