<template>
    <div>
        <zr-form-build @submit="save" @change="formChanged" :value="formScheme.formDefined" ref="ZrFormBuild"/>
        <a-button @click="getData">输出Json</a-button>
        <a-button @click="saveData">保存数据</a-button>
    </div>
</template>


<script>
    import {ZrFormBuild} from "../../../../components";
    import formSchemeApi from "@/shared/service-proxies/formScheme/services/form-scheme-proxies";
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import contract from "../../../linggong/contract/contract";
    import container from "../../../../shared/common/container/container";

    export default {
        name: "FormBuilder",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                mode: '',
                formSchemeInfo: {},
                formScheme: {
                    formDefined: {
                        list: []
                    }, dataStoreConfig: {}
                },
                formData: {},
                containerInstance: null
            }
        },
        created() {
            this.fullData()
            this.init()
        },
        components: {ZrFormBuild},
        methods: {
            formChanged(val, model) {
                console.log('我天表单变了！！')
                console.log(model)
                let item = this.formScheme.formDefined.list.find(i => {
                    console.log(i)
                    return i.model == model
                })

                if (!!item) {
                    this.containerInstance.updateVariable(item.model, this.containerInstance.getNameSpace('form', 'formId', item.options.activedScope, item.model), val)
                }
            },
            getData() {
                this.$refs.ZrFormBuild.getData().then((res) => {
                    console.log(res)
                })
            },
            saveData() {
                let data = {}
                this.$refs.ZrFormBuild.getData().then((res) => {
                    console.log(res)
                    data = res
                    let mainTableId = ''
                    formSchemeApi.saveInstanceForm(this.formSchemeInfo.schemeId,
                        mainTableId,
                        data).then((result) => {
                        console.log(result)
                        // this.formScheme = JSON.parse(formScheme.scheme)
                        // this.$refs.ZrFormBuild.value =  this.formScheme.formDefined
                    })
                })

            },
            init() {
                //创建一个容器
                this.containerInstance = container.create()
                formSchemeApi.getScheme(this.formSchemeInfo.schemeId).then(async (formScheme) => {
                    this.formScheme = JSON.parse(formScheme.scheme)
                    let interfacesList = this.formScheme.interfaceConfig.interfaces
                    let formItemList = this.formScheme.formDefined.list
                    for (let i = 0; i < formItemList.length; i++) {
                        let curFormItem = formItemList[i]
                        this.containerInstance.declareVariable(
                            this.containerInstance.getNameSpace('form', 'formId', curFormItem.options.activedScope, curFormItem.model),
                            '', curFormItem.label,
                            'formId',
                            'from')
                    }

                    let instancedInterfaces = []
                    for (let i in interfacesList) {
                        //创建接口实例
                        instancedInterfaces.push(await this.containerInstance.createInterfaceInstanceById(interfacesList[i].id))
                    }
                    for (let i = 0; i < formItemList.length; i++) {
                        let that = this
                        let curFormItem = formItemList[i]
                        console.log(curFormItem)
                        //无条件显示默认为真
                        curFormItem.options.conditionProofed = true
                        //订阅条件显示变量
                        if (curFormItem.options.condition) {
                            //有条件显示先设为假
                            curFormItem.options.conditionProofed = false
                            let targetValue = curFormItem.options.conditionValue
                            this.containerInstance.subscriptVariable(curFormItem, curFormItem.model, this.containerInstance.getNameSpace('form', 'form', 'global', curFormItem.options.conditionField),
                                function (item, model, val) {
                                    console.log('-------------------updated！！！！---------------------')
                                    if (val == targetValue) {
                                        item.options.conditionProofed = true
                                    } else {
                                        item.options.conditionProofed = false
                                    }
                                })
                        }
                        if (!!curFormItem.options.relatedVariable) {
                            this.containerInstance.subscriptVariable(curFormItem, curFormItem.model, this.containerInstance.getNameSpace('form', 'form', 'global', curFormItem.options.relatedVariable),
                                function (item, model, val) {
                                    console.log('-------------------update ！！！！---------------------')
                                    let obj = {}
                                    obj[model] = val
                                    that.$refs.ZrFormBuild.setData(obj)
                                    //更新在上下文中的变量
                                    that.containerInstance.updateVariable(item.model, that.containerInstance.getNameSpace('form', 'formId', item.options.activedScope, item.model), val)
                                })
                        }
                        if (!!curFormItem.options.sources && curFormItem.options.sources == 'dataSource.interface') {
                            console.log('我的数据来自接口哦')
                            console.log(curFormItem)

                            let interfaceInstance = that.containerInstance.getInterfaceInstanceByOriginId(curFormItem.options.sourcesKey)
                            console.log(interfaceInstance)
                            if (!!interfaceInstance) {
                                console.log('-------------------成功找到目标接口实例！！！！---------------------')
                                let targetField = interfaceInstance.fields.find(item => {
                                    return item.path == curFormItem.options.sourcesField
                                })
                                console.log(targetField)
                                if (!!targetField) {
                                    console.log('-------------------成功找到目标字段！！！！---------------------')
                                    let targetValPath = that.containerInstance.getNameSpace('interface', interfaceInstance.id, targetField.activedScope, curFormItem.options.sourcesField)
                                    console.log(targetValPath)
                                    let options = that.containerInstance.getByFullPath(targetValPath)
                                    console.log('-------------------成功找到目标字段！！！！---------------------')
                                    console.log(options)
                                    if (!!options && options != '') {
                                        curFormItem.options.options = options.value
                                    }
                                    that.containerInstance.subscriptVariable(curFormItem, curFormItem.model,
                                        targetValPath,
                                        function (item, model, val) {
                                            console.log('-------------------数据源更新啦！！！！---------------------')
                                            console.log(val)
                                            item.options.options = val
                                        })
                                }
                            }
                        }
                        //
                        // if (curFormItem.config.)
                        // this.containerInstance.declareVariable(
                        //     this.containerInstance.getNameSpace('form','formId',curFormItem.options.activedScope, curFormItem.model),
                        //     '',curFormItem.label,
                        //     'formId',
                        //     'from')
                    }
                })
                //
                // let formDefined = {
                //     "list": [{
                //     "type": "input",
                //     "label": "业务表单名称",
                //     "options": {
                //         "type": "text",
                //         "width": "100%",
                //         "defaultValue": "",
                //         "placeholder": "请输入",
                //         "clearable": false,
                //         "maxLength": null,
                //         "hidden": false,
                //         "disabled": false
                //     },
                //     "model": "input_1610099536352",
                //     "key": "input_1610099536352",
                //     "rules": [{
                //         "required": false,
                //         "message": "必填项"
                //     }],
                //     "table": "FormSchemeInfo",
                //     "field": "Name"
                // }, {
                //     "type": "select",
                //     "label": "表单类别",
                //     "icon": "icon-xiala",
                //     "options": {
                //         "width": "100%",
                //         "multiple": false,
                //         "disabled": false,
                //         "clearable": false,
                //         "hidden": false,
                //         "placeholder": "请选择",
                //         "dynamicKey": "",
                //         "dynamic": true,
                //         "options": [{
                //             "value": "1",
                //             "label": "下拉框1"
                //         }, {
                //             "value": "2",
                //             "label": "下拉框2"
                //         }],
                //         "showSearch": false,
                //         "sources": "dataSource.dataItem",
                //         "sourcesKey": "form.type"
                //     },
                //     "model": "select_1610099537880",
                //     "key": "select_1610099537880",
                //     "rules": [{
                //         "required": false,
                //         "message": "必填项"
                //     }],
                //     "table": "FormSchemeInfo",
                //     "field": "Category"
                // }, {
                //     "type": "textarea",
                //     "label": "表单描述",
                //     "icon": "icon-edit",
                //     "options": {
                //         "width": "100%",
                //         "minRows": 4,
                //         "maxRows": 6,
                //         "maxLength": null,
                //         "defaultValue": "",
                //         "clearable": false,
                //         "hidden": false,
                //         "disabled": false,
                //         "placeholder": "请输入"
                //     },
                //     "model": "textarea_1610099537547",
                //     "key": "textarea_1610099537547",
                //     "rules": [{
                //         "required": false,
                //         "message": "必填项"
                //     }],
                //     "table": "FormSchemeInfo",
                //     "field": "Description"
                // }],
                //     "config": {
                //     "layout": "horizontal",
                //         "labelCol": {
                //         "xs": 4,
                //             "sm": 4,
                //             "md": 4,
                //             "lg": 4,
                //             "xl": 4,
                //             "xxl": 4
                //     },
                //     "wrapperCol": {
                //         "xs": 18,
                //             "sm": 18,
                //             "md": 18,
                //             "lg": 18,
                //             "xl": 18,
                //             "xxl": 18
                //     },
                //     "hideRequiredMark": false,
                //         "customStyle": ""
                // }
                // }
                // this.$refs.ZrFormBuild.value =  formDefined
            },
            save(data, data2) {
                console.log(data, data2)
            }
        }
    }
</script>

<style scoped>

</style>