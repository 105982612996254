<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span  >表空间选择</span>
            </div>
        </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
        <a-form-model-item label="表空间" prop="name">
            <a-select :showSearch="true" placeholder="请选择表空间" :fieldMap="fieldMap" v-model="formModel.tableName" :options="tableOptions">
            </a-select>
        </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
        <a-button type="danger" @click="cancel">取消</a-button>
        <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
   import databaseApi  from "../../../shared/service-proxies/dataBase/services/database-proxies";
    export default {
        name: "table-select-from",
        mixins: [AppComponentBase, ModalComponentBase],
        data(){
             return {
                 spinning:false,
                 fieldMap:{
                     key:'name',
                     label:'name',
                     value:'pk'
                 },

                 tables:[],
                 formModel:{
                      tableName:""
                 },
                 validModel:{},
                 formItemLayout:"",

             }
        }
        ,created() {
            this.loadTables()
        },
         computed:{
             tableOptions(){
                  return  this.tables.map((item)=>{
                      return {
                          label:item.name,
                          value:item.name,
                          title:item.name
                      }
                  })
             }
         },
         methods:{
             confirmEvent(){
                 console.log('confirmEvent！！')
                 let tableInfo =  this.tables.find((item)=>{
                      return  item.name   ==  this.formModel.tableName
                 })
                  console.log(tableInfo)
                 console.log(this.tables)
                 this.confirm(tableInfo)
                 this.close()
             },
             cancel(){
                 this.close()
             },
             loadTables(){
                 databaseApi.getTables().then(
                     (data)=>{
                         console.log('databaseApi.getTables')
                         console.log(data)
                         this.tables = data.items
                     }
                 ).catch((error)=>{console.log(error)})
             }
         }
    }
</script>

<style scoped>

</style>